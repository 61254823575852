import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import Main from '../Main/Main.js';
import Contact from '../Contact/Contact.js'
import About from '../About/About.js'
import Login from '../Login/Login.js'
import { useTranslation } from 'react-i18next';
import Offices from '../Offices/Offices.js';
import Services from '../Services/Services.js';
import ThankYou from '../ThankYou/ThankYou.js';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import ScrollToTopOnRouteChange from '../ScrollToTopOnRouteChange/ScrollToTopOnRouteChange.js';
import { CurrentLangContext } from '../contexts/CurrentLangContext.js';

function App() {
    const { t, i18n } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const containerRef = useRef(null);
    const [currentLang, setCurrentLang] = useState('en');

    const changeLanguage = (lang, e) => {
        e.preventDefault();
        setCurrentLang(lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const newWidth = window.innerWidth;
                const newHeight = window.innerHeight;
                setWindowWidth(newWidth);
                setWindowHeight(newHeight);
            }, 500);
        };
        
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, [windowWidth, windowHeight]);

    return (
        <CurrentLangContext.Provider value={ currentLang }>
            <div className="page">
            <LocomotiveScrollProvider
                options={{
                    smooth: true,
                    scrollFromAnywhere: true,
                    lerp: 0.1,
                    tablet: {
                        smooth: true,
                        multiplier: 5,
                        lerp: 1,
                        getSpeed: true,
                        touchMultiplier: 4,
                    },
                    smartphone: {
                        smooth: true,
                        multiplier: 5,
                        lerp: 1,
                        getSpeed: true,
                        touchMultiplier: 4,

                    }
                }}
                containerRef={containerRef}
            >
                <BrowserRouter >
                    <Header 
                        changeLanguage={changeLanguage} 
                        t={t}
                        windowWidth={windowWidth}
                    />
                    <ScrollToTopOnRouteChange />
                    <div data-scroll-container ref={containerRef}>
                        <Routes>
                            <Route path="/" element={<Main windowWidth={windowWidth} windowHeight={windowHeight} t={t}/>} />
                            <Route path="/offices" element={<Offices windowWidth={windowWidth} t={t}/>} />
                            <Route path="/services" element={<Services windowWidth={windowWidth} windowHeight={windowHeight} t={t}/>} />
                            <Route path="/about" element={<About t={t}/>} />
                            <Route path="/contact" element={<Contact t={t}/>} />
                            <Route path="/login" element={<Login t={t}/>} />
                            <Route path="/thank" element={<ThankYou t={t}/>} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>  
                        <Footer t={t}/>
                    </div>
                </BrowserRouter>
            </LocomotiveScrollProvider>
            </div>
        </CurrentLangContext.Provider>
    );
}

export default App;
