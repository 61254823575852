import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { colors } from '../../constants/data';
import 'swiper/css';
import { imageCarouselList } from '../../constants/data-main-page';

function Carousel( props ) {
    const { windowWidth } = props;
    const [slidesOffsetBefore, setSlidesOffsetBefore] = useState(0);

    function getColor(index) {
        return colors[index % colors.length];
    }

    useEffect(() => {
        const swiperSlides = document.querySelectorAll('.swiper-slide');
        swiperSlides.forEach((slide, index) => {
            const color = getColor(index);
            slide.style.border = `2px solid ${color}`;
        });
    }, []);

    useEffect(() => {
        if (windowWidth <= 1004 ) {
            setSlidesOffsetBefore(80);
        }  else {
            setSlidesOffsetBefore(0);
        }
    }, [windowWidth]);
    
    return (
        <div className="carousel slider-section">
            <Swiper
                className='swiper-container'
                modules={[Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 32,
                    },
                    '@0.75': {
                      slidesPerView: 1.5,
                      spaceBetween: 32,
                    },
                    '@1.00': {
                      slidesPerView: 2.5,
                      spaceBetween: 40,
                    },
                    '@1.50': {
                      slidesPerView: 3.5,
                      spaceBetween: 40,
                    },
                  }}
                loop={true}
                slidesOffsetBefore = {slidesOffsetBefore}
                grabCursor={true}
                centeredSlides={true}>
                    {imageCarouselList.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <img className="swiper-slide" src={slide.picture} alt="Swiper"/>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
}

export default Carousel;

