import React from 'react';

function AmenitiesBlock( props ) {

    const { info, index, t } = props;

    return (
        <div className='amenities-block__info'>
            <img 
                className='amenities-block__icon' 
                src={require(`../../../img/icons/black/${info.icon}`)} 
                alt='meeting'
            >
            </img>
            <div className='amenities-block__text'>
                <h3 className='amenities-block__text_title'>{t(`services.blocks.block_${ index }.title`)}</h3>
                <p className='amenities-block__text_subtitle'>{t(`services.blocks.block_${ index }.text`)}</p>
                {info.text_2 && <p className='amenities-block__text_subtitle'><br/>{t(`services.blocks.block_${ index }.text_2`)}</p>}
                
                { info.link &&
                    <p className='amenities-block__text_subtitle'><br/>
                        {t(`services.blocks.block_${ index }.link.text`)}<a 
                            className='amenities-block__link' 
                            href={t(`services.blocks.block_${ index }.link.link`)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                           {t(`services.blocks.block_${ index }.link.text_link`)}
                        </a>
                    </p>
                }
            </div>
        </div>
    );
}

export default AmenitiesBlock;
