import { ReactComponent as Email } from '../img/icons/black/mail-address.svg';
import { ReactComponent as Furniture } from '../img/icons/black/furniture.svg';
import { ReactComponent as Supplies } from '../img/icons/black/office-supplies.svg';
import { ReactComponent as Service } from '../img/icons/black/concierge.svg';
import { ReactComponent as Ventilation } from '../img/icons/black/ventilation.svg';
import { ReactComponent as Cleaning } from '../img/icons/black/cleaning.svg';
import { ReactComponent as Wifi } from '../img/icons/black/wifi.svg';
import { ReactComponent as Booth } from '../img/icons/black/phone-booth.svg';
import { ReactComponent as Parking } from '../img/icons/black/bike-parking.svg';
import { ReactComponent as Tea } from '../img/icons/black/tea-corner.svg';
import { ReactComponent as Security } from '../img/icons/black/privacy-security.svg';
import { ReactComponent as Terrace } from '../img/icons/black/outdoor-terrace.svg';

const iconOfficeList = [
    { Picture: Furniture, title_1: 'Ergonomic furniture', title_2: 'Mobilier ergonomique'},
    { Picture: Supplies, title_1: 'Business class printers, office supplies and paper shredder', title_2: 'Imprimantes de classe affaires, fournitures de bureau et destructeur de documents'},
    { Picture: Email, title_1: 'Professional address for mail delivery', title_2: 'Adresse professionnelle pour la livraison du courrier' },
    { Picture: Service, title_1: 'Concierge service', title_2: 'Service de conciergerie' },
    { Picture: Cleaning, title_1: 'Cleaning services', title_2: 'Services de nettoyage' },
    { Picture: Ventilation, title_1: 'Advanced ventilation system', title_2: 'Système de ventilation avancé' },
    { Picture: Security, title_1: 'Privacy & Security', title_2: 'Confidentialité et sécurité' },
    { Picture: Wifi, title_1: 'High speed Wi-Fi', title_2: 'Wi-Fi haute vitesse' },
    { Picture: Booth, title_1: 'Phone booths for private and ad-hoc meetings', title_2: 'Cabines téléphoniques pour réunions privées et impromptues' },
    { Picture: Parking, title_1: 'Bike parking', title_2: 'Parking à vélos' },
    { Picture: Tea, title_1: 'Tea corner', title_2: 'Coin thé' },
    { Picture: Terrace, title_1: 'Outdoor terrace', title_2: 'Terrasse extérieure' }
];

const officeList = [
    {
        picture: 'Everest.png', 
        plan: 'Everest_plan.png',
        title:'Everest', 
        subtitle_1:'Reach new heights of success with Everest office! Step into a workspace that embodies ambition and excellence.',
        subtitle_2:"Atteignez de nouveaux sommets de succès avec le bureau Everest ! Entrez dans un espace de travail qui incarne l'ambition et l'excellence.",
        persons: {
            'value':'6',
            'span' : 'persons max.'
        },
        square:{
            'value':'23.93',
            'span' : 'sq m'
        },
        price:{
            'value':'2 168, 73',
            'span' : 'excl VAT/ month'
        },
    },
    {
        picture: 'Elbrus.png', 
        plan: 'Elbrus_plan.png',
        title:'Elbrus', 
        subtitle_1:'Discover the heights of productivity in Elbrus office! Experience a workspace designed to elevate your business to new peaks.',
        subtitle_2: "Découvrez les sommets de la productivité dans le bureau Elbrus ! Expérimentez un espace de travail conçu pour élever votre entreprise à de nouveaux sommets.",
        persons: {
            'value':'5',
            'span' : 'persons'
        },
        square:{
            'value':'26,6',
            'span' : 'sq m'
        },
        price:{
            'value':'2 410, 70',
            'span' : '/ month'
        },
    },
    {
        picture: 'Fujiyama.png', 
        plan: 'Fujiyama_plan.png',
        title:'Fujiyama', 
        subtitle_1:'Peak performance awaits at Fujiyama office! Immerse yourself in a workspace that exudes creativity and innovation',
        subtitle_2: "La performance maximale vous attend au bureau Fujiyama ! Plongez dans un espace de travail qui respire la créativité et l'innovation.",
        persons: {
            'value':'2-3',
            'span' : 'persons'
        },
        square:{
            'value':'13,93',
            'span' : 'sq m'
        },
        price:{
            'value':'1262, 45',
            'span' : '/ month'
        },
    },
    {
        picture: 'Montblanc.png', 
        plan: 'Montblanc_plan.png',
        title:'Mont Blanc', 
        subtitle_1:'Ascend to excellence at Mont Blanc office! Elevate your workday with a prestigious workspace that combines luxury and functionality',
        subtitle_2: "Atteignez l'excellence au bureau Mont Blanc ! Élevez votre journée de travail avec un espace prestigieux qui allie luxe et fonctionnalité.",
        
        persons: {
            'value':'2',
            'span' : 'persons'
        },
        square:{
            'value':'9,81',
            'span' : 'sq m'
        },
        price:{
            'value':'890',
            'span' : '/ month'
        },
    },
    {
        picture: 'Ararat.png', 
        plan: 'Ararat_plan.png',
        title:'Ararat', 
        subtitle_1:'Rise above the competition at Ararat office! Experience a workspace that inspires greatness and fosters collaboration',
        subtitle_2: "Surpassez la concurrence au bureau Ararat ! Découvrez un espace de travail qui inspire la grandeur et favorise la collaboration.",

        persons: {
            'value':'2',
            'span' : 'persons'
        },
        square:{
            'value':'9,81',
            'span' : 'sq m'
        },
        price:{
            'value':'890',
            'span' : '/ month'
        },
    },
]

export {
    iconOfficeList,
    officeList,
}