import React, { useState, useEffect } from 'react';
import { AccordItem } from './AccordItem';
import ContactUsLink from '../../ContactUsLink/ContactUsLink';

function Faq( props ) {
    const { t } = props;
    const [openID, setOpenID] = useState(null);
    const [countList, setCountList] = useState(0);

    useEffect(() => {
        let i = 0;
        do {
            i++;
        } while (!t(`main.faq.questions.faq_${i}.question`).startsWith('main.'));
        setCountList(i - 1);
    }, [t]);


    return (
        <section 
            className='faq' 
            id='faq'
            data-scroll-call='faq'
            data-scroll-target
        >
            <div className='faq__container'>
                <h2 className='faq__title'>{t(`main.faq.title`)}</h2>
                <ul className='faq__accord'>
                    {Array.from({ length: countList }, (_, id) => (
                        <AccordItem 
                            onClick={() => id === openID ? setOpenID(null) : setOpenID(id)} 
                            isOpen={id === openID} 
                            key={ id }
                            index = { id }
                            t = { t }
                        />
                    ))}
                </ul>
                <ContactUsLink  t = { t }/>
            </div>
        </section>
    );
}

export default Faq;
