import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrentLangContext } from '../../contexts/CurrentLangContext';

function OfficeItem(props) {
    const { id, item, color, windowWidth } = props;
    const [isMobile, setIsMobile] = useState(false);
    const [planOpen, setPlanOpen] = useState(null);
    const [onButtonMove, setOnButtonMove] = useState(false);
    const navigate = useNavigate();
    const currentLang = useContext(CurrentLangContext);
    
    const handleBook = () => {
        navigate('/', { state: { nameOffice: item.title } });
    };

    const handleClick = (id) => {
        id === planOpen ? setPlanOpen(null) : setPlanOpen(id);
    };

    const handleMouseMove = (id) => {
        if (!isMobile) setPlanOpen(id);
    };

    const handleMouseLeave = (id) => {
        if (!isMobile) setPlanOpen(null);
    };

    useEffect(() => {
        setIsMobile(windowWidth <= 1024);
    }, [windowWidth]);

    return (
        <div className='office__item' style={{ border: `4px solid ${color}` }}>
            <div className='office__images_container'>
                <img 
                    className={`office__item_image ${id === planOpen ? 'office__item_image-hover' : ''}`}
                    src={require(`../../../img/offices_page/office_photo/${item.picture}`)}
                    alt='office'
                    onMouseMove={() => handleMouseMove(id)}
                    onMouseLeave={() => handleMouseLeave(id)}
                    onClick={() => handleClick(id)}
                />
                <img 
                    className='office__item_image-plan' 
                    src={require(`../../../img/offices_page/office_photo/${item.plan}`)} 
                    alt='office'
                />
            </div>
            <div className='office__item_info'>
                <h2 className='office__item_title'>{item.title}</h2>
                <p className='office__item_subtitle'>{currentLang === 'en' ? item.subtitle_1 : item.subtitle_2}</p>
                <div className='office__item_param-list'>
                    <div className='office__item_param'>
                        <img className='office__param_icon' src={require(`../../../img/offices_page/persons.svg`).default} alt="persons" />
                        <div className='office__param_title'>
                            <p className='office__param_value'>{item.persons.value}</p>
                            <span className='office_param_span'>{item.persons.span}</span>
                        </div>    
                    </div>
                    <div className='office__item_param'>
                        <img className='office__param_icon' src={require(`../../../img/offices_page/square.svg`).default} alt='square' />
                        <div className='office__param_title'>
                            <p className='office__param_value'>{item.square.value}</p>
                            <span className='office_param_span'>{item.square.span}</span>
                        </div>
                    </div>
                    <div className='office__item_param'>
                        <img className='office__param_icon' src={require(`../../../img/offices_page/price.svg`).default} alt='price' />
                        <div className='office__param_title'>
                            <p className='office__param_value'>{item.price.value}</p>
                            <span className='office_param_span'>{item.price.span}</span>
                        </div>
                    </div>
                </div>
                <button 
                    onClick={handleBook}
                    className='office__item_submit btn' 
                    style={{ 
                        border: `2px solid ${color}`,
                        color: onButtonMove ? 'white' : color,
                        background: onButtonMove ? color : 'transparent'
                    }}
                    onMouseMove={()=> setOnButtonMove(true)}
                    onMouseLeave={()=> setOnButtonMove(false)}
                >
                    {`Book ${item.title}`}
                </button>
            </div>
        </div>
    );
}

export default OfficeItem;
