import React from 'react';

function About( props ) {
    const { t } = props;

    return (
        <main className='about' data-scroll-section>
            <div className='about__container'>
                <div className='about__info'>
                    <h1 className='about__title'>
                        {t('about.title.upper_text')}<br/>
                        <span className='about__title_regular'>{t('about.title.lower_text')}</span>
                    </h1>
                    <div className='about__image_container'>
                        <img 
                            className='about__image' 
                            src={require('../../img/aboutus/aboutus.jpg')}
                            alt='house'>
                        </img>
                    </div>
                    <p className='about__aboutus_text'>
                        {t('about.text.paragraph_1')}
                        <span className='line-break'></span>
                        <span className='line-break-mobile'></span>
                        {t('about.text.paragraph_2')}<br/>
                        <span className='line-break'></span>
                        <span className='line-break-mobile'></span>
                        {t('about.text.paragraph_3')}
                    </p>
                </div>
            </div>
        </main>
    );
}

export default About;