import React, { useState, useEffect } from 'react';
import Cards from './Cards/Cards';
import AmenitiesBlock from '../Services/AmenitiesBlock/AmenitiesBlock';
import ParallaxComponent from '../Parallax/ParallaxComponent';
import { 
    amenitiesList, 
    ImageParallax_1, 
    ImageParallax_2, 
    ImageParallax_3, 
    ImageParallax_4 
} from '../../constants/data-services-page';



function Services( props ) {
    const { windowWidth, windowHeight, t } = props;
    const [backPositionY, setBackPositionY] = useState(-200);
    const [backPositionX, setBackPositionX] = useState(0);
    const [heightParallax, setHeightParallax] = useState('200%');
    const [speedParallax, setSpeedParallax] = useState(-2);

    useEffect(() => {
        if (windowWidth > 1575) {
            setBackPositionY(-150);
            setSpeedParallax(-5);
            setBackPositionX(0);
            const newHeight = (windowWidth * 2) / 4;
            setHeightParallax(`${newHeight}px`);
        } if (windowWidth <=1575 && windowWidth > 1280) {
            setBackPositionY(-150);
            setSpeedParallax(-5);
            setBackPositionX((0 * windowWidth) / 100);
            const newHeight = (windowWidth * 2) / 3;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1280 && windowWidth > 1020){
            setBackPositionY(-350);
            setBackPositionX((-30 * windowWidth) / 100);
            setSpeedParallax(-2);
            const newHeight = (windowWidth * 2) / 2.2;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1020 && windowWidth > 500){
            setBackPositionY(-200);
            setBackPositionX((-24 * windowWidth) / 100);
            setSpeedParallax(-1.5);
            const newHeight = (windowWidth * 2) / 2.4;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 500){
            setBackPositionY(0);
            setBackPositionX((-30 * windowWidth) / 100);
            setSpeedParallax(-1);
            const newHeight = (windowWidth * 2) /2.2;
            setHeightParallax(`${newHeight}px`);
        }
    }, [windowWidth, windowHeight]);

    return (
        <main className='services' data-scroll-section>

            <div className='services__container'>
                <h1 className='services__title'>{t('services.title')}</h1>
                <p className='services__subtitle'>{t('services.subtitle')}</p>
                <Cards windowWidth={ windowWidth } t={t}/>
            </div>

            <section className='services__amenites'>
                <h2 className='services__amenites_title'>{t('services.additional.title')}</h2>
                <p className='services__amenites_subtitle services__amenites_subtitle-hidden'>{t('services.additional.subtitle')}</p>
            </section>

            <AmenitiesBlock info={amenitiesList[0]} index={1} t={t}/>
            <ParallaxComponent 
                image={ ImageParallax_1 } 
                backPositionX = {backPositionX}
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {100}
                speedParallax = {speedParallax} 
            ></ParallaxComponent>

            <AmenitiesBlock info={amenitiesList[1]} index={2} t={t}/>
            <ParallaxComponent 
                image={ ImageParallax_2 } 
                backPositionX = {backPositionX}
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {100}
                speedParallax = {speedParallax}
            ></ParallaxComponent>
            <AmenitiesBlock info={amenitiesList[2]} index={3} t={t}/>

            <section className='services__amenites'>
                <h2 className='services__amenites_title'>{t('services.amenities.title')}</h2>
                <p className='services__amenites_subtitle'>{t('services.amenities.subtitle')}</p>
            </section>

            <AmenitiesBlock info={amenitiesList[3]} index={4} t={t}/>
            <ParallaxComponent 
                image={ ImageParallax_3 } 
                backPositionX = {backPositionX}
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {100}
                speedParallax = {speedParallax}
            ></ParallaxComponent>

            <AmenitiesBlock info={amenitiesList[4]} index={5} t={t}/>
            <ParallaxComponent 
                image={ ImageParallax_4 } 
                backPositionX = {backPositionX}
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {100}
                speedParallax = {speedParallax}
            ></ParallaxComponent>
        </main>
    );
}

export default Services;
