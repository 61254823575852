import React, { useState, useEffect } from 'react';
import { colors } from '../../../constants/data';
import { cardList } from '../../../constants/data-services-page';


function Cards( props ) {
    const { windowWidth, t } = props;
    const [openID, setOpenID] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

//функция перевода в rgba, для возможности установки прозрачности
    function hexToRGBA(hex, alpha = 1) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const handleClick = (id) => {
        id === openID ? setOpenID(null) : setOpenID(id);
    };

    const handleMouseMove = (id) => {
        if (!isMobile) setOpenID(id);
    };

    const handleMouseLeave = (id) => {
        if (!isMobile) setOpenID(null);
    };

    useEffect(() => {
        setIsMobile(windowWidth <= 1024);
    }, [windowWidth]);

    return (
        <section className='cards'>
            <h2 className='cards_title'>{t('services.cards.title')}</h2>
            <div className='cards__container'>
                {cardList.map((item, id) => (
                    <div 
                    className={`card ${id === openID ? 'is-flipped' : ''}`}
                    key={id}
                    onClick={() => handleClick(id)}
                    onMouseMove={() => handleMouseMove(id)}
                    onMouseLeave={() => handleMouseLeave(id)}
                    >
                        <div 
                            className="card__side card__side--front"
                            style={{border: `4px solid ${colors[id % colors.length]}`}}
                        >
                            <img 
                                className='card__icon' 
                                src={require(`../../../img/icons/black/${item.icons}`)} 
                                alt={t(`services.cards.card_${id + 1}.title`)}
                            />
                            <h3 className='card__icon_title' >{t(`services.cards.card_${id + 1}.title`)}</h3>
                        </div>
                        <div 
                            className="card__side card__side--back"
                            style={{
                                background: hexToRGBA(colors[id % colors.length], 0.25)
                            }}
                        >
                            <p className='card__side-back_text'>{t(`services.cards.card_${id + 1}.text`)}</p>
                        </div>
                    </div>
                ))}
            </div>   
        </section>
    );
}

export default Cards;
