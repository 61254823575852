import React from 'react';
import { NavLink } from 'react-router-dom';

function ContactUsLink ( props ) {
    const { t } = props;
    return (
        <p 
            className='contactus__subtitle'>{t('main.faq.subtitle.text')}
                <NavLink 
                    className='contactus__subtitle_link' 
                    to={t('main.faq.subtitle.link')}
                >
                {t('main.faq.subtitle.text_link')}
                </NavLink>
        </p>
    );
}

export default ContactUsLink;