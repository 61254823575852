import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { slideListIntro } from '../../../constants/data-main-page';

function Intro( props ) {
    const { t } = props;
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const intervalTime = 5000; // время интервала в миллисекундах
    let intervalRef = null;

    useEffect(() => {
        startInterval();
        return () => clearInterval(intervalRef);
    }, [isHovered]);

    const startInterval = () => {
        clearInterval(intervalRef);
        intervalRef = setInterval(() => {
            if (!isHovered) {
                setActiveIndex(prevIndex => (prevIndex + 1) % slideListIntro.length);
            }
        }, intervalTime);
    };

    const nextSlide = () => {
        setActiveIndex(prevIndex => (prevIndex + 1) % slideListIntro.length);
    };

    const prevSlide = () => {
        setActiveIndex(prevIndex => (prevIndex - 1 + slideListIntro.length) % slideListIntro.length);
    };

    return (
        <section className='intro' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className='intro__slider'>
            {slideListIntro.map((slide, index) => (
                <img
                    className={activeIndex === index ? 'intro__image intro__slider_active' : 'intro__image'}
                    src={slide.picture}
                    key={index}
                    alt=''
                />
            ))}
                <div className='intro__slider_blackout'></div>
                <a className='intro__slider_btn intro__slider_prev' onClick={prevSlide}>
                    <div className='intro__slider_arrow intro__slider_arrow-prev'/>
                </a>
                <a className='intro__slider_btn intro__slider_next' onClick={nextSlide}>
                    <div className='intro__slider_arrow intro__slider_arrow-next'/>
                </a>
            </div>
            <div className='intro__container'>
                <div>
                    <h1 className='intro_title'>{t('main.intro.title_upper')}</h1>
                    <h1 className='intro_title'>{t('main.intro.title_lower')}</h1>
                </div>
                <h1 className='intro_title_mobile'>{t('main.intro.title_mobile')}</h1>
                <p className='intro_subtitle'>{t('main.intro.subtitle')}</p>
                <div className='intro__btns_container'>
                    <button 
                        className='btn intro__btn'
                        onClick={ () => navigate('/contact') }
                    >
                        {t('main.intro.button_1')}
                    </button>
                    <button 
                        className='btn intro__btn'
                        onClick={ () => navigate('/', { state: { nameOffice: ' ' } }) }
                    >
                        {t('main.intro.button_2')}
                    </button>
                </div>  
            </div>
        </section>
    );
}

export default Intro;
