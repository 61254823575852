import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import IconOfficePage from '../Offices/Icon_offices-page/Icon_office-page'
import OfficeItem from './OfficeItem/OfficeItem';
import ContactUsLink from '../ContactUsLink/ContactUsLink';
import { iconOfficeList, officeList } from '../../constants/data-offices-page';
import { colors } from '../../constants/data';
import { CurrentLangContext } from '../contexts/CurrentLangContext';

function Offices( props ) {
    const { windowWidth, t } = props;
    const firstColumnIcons = iconOfficeList.slice(0, 6);
    const secondColumnIcons = iconOfficeList.slice(6);
    const currentLang = useContext(CurrentLangContext);

    return (
        <main className='offices' data-scroll-section>
            <div className='offices__container'>
                <h1 className='offices__title'>{t('offices.title_upper')}</h1>
                <h1 className='offices__title offices__title_regular'>{t('offices.title_lower')}</h1>
                <div className='offices__intro_container'>
                    <div className='offices__intro_block-left'>
                        <div className='offices__icons_container'>
                            <div className='offices__icons'>
                                {firstColumnIcons.map((icon, index) => (
                                    <IconOfficePage key={index} Picture={icon.Picture} title={currentLang === 'en' ? icon.title_1 : icon.title_2}/>
                                ))}
                            </div>
                            <div className='offices__icons'>
                                {secondColumnIcons.map((icon, index) => (
                                    <IconOfficePage key={index} Picture={icon.Picture} title={currentLang === 'en' ? icon.title_1 : icon.title_2}/>
                                ))}
                            </div>
                        </div>
                        <NavLink className='office__intro_link' to={t('offices.link_arrow.link')}>
                            <p>{t('offices.link_arrow.text_link')}</p>
                            <span className='arrow__link'></span>
                        </NavLink>
                    </div>
                    <div className='offices__plan'>
                        <img 
                            className='offices__plan_image'
                            src={require('../../img/offices_page/coworking_plan.png')}
                            alt='office plan'
                        >
                        </img>
                        <a className='offices__plan_link' href='#/'>{t('offices.download_plan')}</a>
                    </div>
                </div>
                <div className='offices__list'>
                    {officeList.map((item, index) => (
                        <OfficeItem 
                            key = { index } 
                            id = {index }
                            item = { item }
                            color = {colors[index % colors.length]}
                            windowWidth={windowWidth}
                        />
                    ))}
                </div>
            </div>
            <ContactUsLink t={ t }/>
        </main>
    );
}

export default Offices;

