import React from 'react';

function Login( props ) {
    const { t } = props;

    return (
        <main className='login' data-scroll-section>
            <div className='login__container'>
                <div className='login__image_container'>
                    <img 
                        className='login__image' 
                        src={require('../../img/login/login.png')}
                        alt='work'>
                    </img>
                </div>
                <form className='login__form'>
                    <h2 className='login__title'>{t('login.title')}</h2>
                    <p className='login__subtitle'>{t('login.subtitle')}</p>
                        <div className='login__email_container'>
                            <label className='login__label' htmlFor='email'>{t('login.email')}</label>
                            <input 
                                id='email'
                                className='login__input login__input_email' 
                                type='email'
                                placeholder='XXXXXXX@YYYYY.ZZ' 
                                >
                            </input>
                        </div>
                    <button className='login__submit btn ' type='submit'>{t('login.button')}</button>
                </form>
            </div>
        </main>
    );
}

export default Login;
