
import ImageParallax_1 from '../img/parallax/img_par_6.jpg';
import ImageParallax_2 from '../img/parallax/img_par_2.jpg';
import ImageParallax_3 from '../img/parallax/img_par_4.jpg';
import ImageParallax_4 from '../img/parallax/img_par_5.jpg';

const cardList = [
    { icons: 'privacy-security.svg' },
    { icons: 'cleaning.svg' },
    { icons: 'wifi.svg' },   
    { icons: 'ventilation.svg' },
    { icons: 'tea-corner.svg' },
    { icons: 'concierge.svg' },
    { icons: 'phone-booth.svg' },
    { icons: 'furniture.svg' },
    { icons: 'outdoor-terrace.svg' },
    { icons: 'mail-address.svg' },
    { icons: 'office-supplies.svg' },
    { icons: 'bike-parking.svg' }
];

const amenitiesList = [
    {
        icon:'meeting-room.svg',
    },        
    {
        icon:'gym.svg',
    },        
    {
        icon:'car-parking.svg',
    },        
    {
        icon:'cafe.svg',
        text_2: true
    },        
    {
        icon:'coliving.svg',
        text_2: true,
        link: true
    },
]

export {
    cardList,
    amenitiesList,
    ImageParallax_1,
    ImageParallax_2,
    ImageParallax_3,
    ImageParallax_4,
}