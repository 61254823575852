import React, { useRef } from 'react';

export const AccordItem = ( props ) => {
    const {onClick, isOpen, t, index } = props;
    const itemRef = useRef(null);

    return (
        <li className='faq__accord_item'>
            <button 
                className='faq__accord_header'
                onClick={() => onClick()}
            >
                {t(`main.faq.questions.faq_${index + 1}.question`)}
                <div className={`faq__accord_vert-line ${isOpen ? 'open' : ''}`}></div>
                <div className='faq__accord_goriz-line'></div>
            </button>
            <div 
                className='faq__accord_collapce'
                style={
                    isOpen ? {height: itemRef.current.scrollHeight} : {height: '0px'}
                }
            >
                <div className='faq__accord_body' ref={itemRef}>{t(`main.faq.questions.faq_${index + 1}.answer`)}</div>
            </div>
        </li>
    );
}


