import React from 'react';

function IconOfficePage(props) {
    const { Picture, title } = props;

    return (
        <div className='offices__icon'>
            <div className='offices__icon_container'>
                <Picture className='offices__icon_image'/>
            </div>
            <p className='offices__icon_title'>{title}</p>
        </div>
    );
}

export default IconOfficePage;
