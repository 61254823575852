import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';

function Request( props ) {
    const { nameOffice, t } = props;
    const [dateTo, setDateTo] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [people, setPeople] = useState('');
    const [parking, setParking] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [text, setText] = useState('');
    const navigate = useNavigate();
    const [isOfficeName, setIsOfficeName] = useState('');

    let today = new Date();

    useEffect(() => {
        if (nameOffice) {
            setIsOfficeName(nameOffice);
        }
    }, []);

    const handleChangeNumber = (event, setValue) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '').slice(0, 2);
        const parsedValue = parseInt(inputValue);
        if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 100) {
            setValue(parsedValue.toString());
        } else {
            setValue('');
        }
    };

    const handleChangePhone = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        inputValue = inputValue.startsWith('+') ? inputValue : '+' + inputValue;
        setPhoneNumber(inputValue);
    };

    const handleBlurPhone = () => {
        if (!phoneNumber || phoneNumber === '+') {
            setPhoneNumber('');
        }
    };

    const handleChangeAdditional = (event) => {
      const inputValue = event.target.value;
      if (inputValue.length <= 650) {
        setText(inputValue);
      }
    };

    const handleSubmit = (e) => {
        navigate('/thank', { state: { from: 'request' } });
    }

    function handleOfficeName(e) {
        setIsOfficeName(e.target.value);
    }

    return (
        <section 
            className='request' 
            id='main_request' 
            data-scroll-form
            data-scroll-call='main_request'
            data-scroll-target
        >
            <div className='request__container'>
                <form className='request__form'>
                    <h2 className='request__title'>{t('main.request.title')}</h2>
                    <p className='request__subtitle'>{t('main.request.subtitle')}</p>
                    <div className='request__name_container'>
                        <div className='request__form_row request__first-name_container'>
                            <label className='request__label' htmlFor='first-name'>{t('main.request.first_name_label')}</label>
                            <input 
                                id='first-name'
                                className='request__input request__input_first-name' 
                                type='text' 
                                placeholder='XXXXXXXXXX'>
                            </input>
                        </div>
                        <div className='request__form_row request__last-name_container'>
                            <label className='request__label' htmlFor='last-name'>{t('main.request.last_name_label')}</label>
                            <input 
                                id='last-name'
                                className='request__input request__input_last-name' 
                                type='text'
                                placeholder='XXXXXXXXXX'>
                            </input>
                        </div>
                    </div>
                    <p className='request__label_dates-mobile'>{t('main.request.booking_dates_label')}</p>
                    <div className='request__dates_container'>
                        <p className='request__label_dates'>{t('main.request.booking_dates_label')}:</p>
                        <label className='request__label request__label_date' htmlFor='from'>{t('main.request.from_label')}</label>
                        <Calendar 
                            id="from" 
                            dateFormat="dd/mm/yy"
                            value={dateFrom} 
                            onChange={(e) => setDateFrom(e.value)} 
                            minDate={today} 
                            maxDate={dateFrom} 
                            placeholder='DD/MM/YYYY'
                        />
                        <label className='request__label request__label_date' htmlFor='to'>{t('main.request.to_label')}</label>
                        <Calendar 
                            id="to" 
                            dateFormat="dd/mm/yy" 
                            value={dateTo} 
                            onChange={(e) => setDateTo(e.value)} 
                            minDate={dateFrom}
                            placeholder='DD/MM/YYYY'
                        />
                    </div>
                    <div className='request__office_container'>
                        <div className='flex-column-left'>
                            <label className='request__label' htmlFor='office'>{t('main.request.office_name_label')}</label>
                            <input 
                                id='office'
                                className='request__input request__input_office' 
                                type='text' 
                                placeholder='XXXXXXXX'
                                value={isOfficeName}
                                onChange={handleOfficeName}
                            >
                            </input>
                        </div>
                        <div className='request__office_num-blocks'>
                            <div className='flex-column-left request__people_container'>
                                <label className='request__label' htmlFor='people'>{t('main.request.number_of_people_label')}</label>
                                <input 
                                    id='people'
                                    className='request__input request__input_people' 
                                    type='text' 
                                    value={people}
                                    onChange={(e) => handleChangeNumber(e, setPeople)}
                                    maxLength='2'
                                    placeholder='XX'
                                >
                                </input>
                            </div>
                            <div className='flex-column-left request__parking_containeer'>
                                <label className='request__label' htmlFor='parking'><span className='request__parking_span'>{t('main.request.number_of_label')} </span>{t('main.request.parking_lots_label')}</label>
                                <input 
                                    id='parking'
                                    className='request__input request__input_parking'
                                    type='text'
                                    value={parking}
                                    onChange={(e) => handleChangeNumber(e, setParking)}
                                    maxLength='2'
                                    placeholder='XX'>
                                </input>
                            </div>
                        </div>
                    </div>
                    <div className='request__contact_container'>
                        <div className='flex-column-left request__email_containeer'>
                            <label className='request__label' htmlFor='email'>{t('main.request.email_label')}</label>
                            <input 
                                id='email'
                                className='request__input request__input_email' 
                                type='email'
                                placeholder='XXXXXXXX@YYYYYYYY.ZZZ' 
                                >
                            </input>
                        </div>
                        <div className='flex-column-left request__phone_containeer'>
                            <label className='request__label' htmlFor='phone'>{t('main.request.phone_number_label')}</label>
                            <input 
                                id='phone'
                                className='request__input request__input_phone' 
                                type='tel'
                                value={phoneNumber}
                                onChange={handleChangePhone}
                                onBlur={handleBlurPhone}
                                placeholder='+XXXXXXXXXXXXXX'
                            ></input>
                        </div>
                    </div>
                    <div className='request__additional_container'>
                        <label className='request__label' htmlFor='additional'>{t('main.request.additional_information_label')}</label>
                        <textarea 
                            id='additional'
                            className='request__input request__input_additional' 
                            type='text' 
                            value={text}
                            onChange={handleChangeAdditional}
                            maxLength={650}>
                        </textarea>
                    </div>
                    <div className='request__submit_container'>
                        <button 
                            className='btn' 
                            type='submit'
                            onClick={handleSubmit}
                        >
                        {t('main.request.button')}
                        </button>
                    </div>
                </form>
                <div className='request__map'>
                    <img 
                        className='request__map_image' 
                        src={require('../../../img/MAP.png')}
                        alt='map'>
                    </img>
                </div>

            </div>
        </section>
    );
}

export default Request;
