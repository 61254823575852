import React from 'react';

function ParallaxComponent(props) {
    const { 
        image, 
        backPositionX, 
        backPositionY,
        heightParallax, 
        widthParallax, 
        speedParallax,
        bottomImage,
    } = props;

    return (
        <div className='parallax'>
            <div
                className='paral_img' 
                style={{
                    background: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPositionY: `${backPositionY}px`,
                    backgroundPositionX: `${backPositionX}px`,
                    width: `${widthParallax}%`,
                    height: `${heightParallax}`,
                    bottom: `${bottomImage}`
                }}
                alt='additions'
                data-scroll 
                data-scroll-speed={speedParallax}
            >
            </div>
        </div>
    )
}

export default ParallaxComponent;

