import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Contact( props ) {
    const { t } = props;
    const [text, setText] = useState('');
    const navigate = useNavigate();

    const handleChangeAdditional = (event) => {
      const inputValue = event.target.value;
      if (inputValue.length <= 650) {
        setText(inputValue);
      }
    };

    const handleSubmit = (e) => {
        navigate('/thank', { state: { from: 'message' } });
    }

    return (
        <main className='contact' data-scroll-section>
            <div className='contact__container'>
                <form className='contact__form'>
                    <h2 className='contact__title'>{t('contact.title')}</h2>
                    <p className='contact__subtitle'>{t('contact.subtitle')}</p>
                    <div className='contact__about_container'>
                        <div className='contact__name_container'>
                            <label className='contact__label' htmlFor='first-name'>{t('contact.name')}</label>
                            <input 
                                id='first-name'
                                className='contact__input' 
                                type='text'
                                placeholder='XXXXXXXXXX'>
                            </input>
                        </div>
                        <div className='contact__email_container'>
                            <label className='contact__label' htmlFor='email'>{t('contact.email')}</label>
                            <input 
                                id='email'
                                className='contact__input contact__input_email' 
                                type='email'
                                placeholder='XXXXXXX@YYYYY.ZZ' 
                                >
                            </input>
                        </div>
                    </div>
                    <div className='contact__additional_container'>
                        <label className='contact__label' htmlFor='additional'>{t('contact.your_message')}</label>
                        <textarea 
                            id='additional'
                            className='contact__input contact__input_additional' 
                            type='text' 
                            value={text}
                            onChange={handleChangeAdditional}
                            maxLength={650}>
                        </textarea>
                    </div>
                    <button 
                        className='contact__submit btn ' 
                        type='submit'
                        onClick={handleSubmit}
                    >
                    {t('contact.button')}
                    </button>
                </form>
                <div className='contact__image_container'>
                    <img 
                        className='contact__image' 
                        src={require('../../img/contactus/contactus.jpg')}
                        alt='house'>
                    </img>
                </div>
            </div>
        </main>
    );
}

export default Contact;
