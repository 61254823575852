import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function Header( props ) {
    const { changeLanguage, t, windowWidth } = props;
    const [isSmoothAppearance, setIsSmoothAppearance] = useState(false);
    const [isSlideMenuOpen, setIsSlideMenuOpen] = useState(false);
    const [isSlideMenuCLose, setIsSlideMenuClose] = useState(false);
    const [isLandguageEnglish, setIsLandguageEnglish] = useState(true);

    const handleClickLogo = () => {
        closeMenu(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsSmoothAppearance(true);
        }, 500);
    }, []);

    function handleCheckboxChange (e) {
        setIsSlideMenuOpen(e.target.checked);
        if (!e.target.checked) {
            setIsSlideMenuClose(true);
        }
    }

    function handleLang (lang, e) {
        e.preventDefault();
        changeLanguage(lang, e)
        if (lang === 'en') {
            setIsLandguageEnglish(true);
        } else {
            setIsLandguageEnglish(false);
        }
    }

    function closeMenu (close) {
        setIsSlideMenuClose(close);
        setIsSlideMenuOpen(!close);
    }

    useEffect(() => {
        if (windowWidth >= 850 ) {
            setIsSlideMenuOpen(false);
            setIsSlideMenuClose(false);
        }
    }, [windowWidth]);

    return (
        <>
            <header className="header">
                <div className='header__container'>
                    <NavLink className='header__logo_container' to='/' onClick={handleClickLogo}>
                        <img
                            className={`header__logo header__animated ${isSmoothAppearance ? 'header--visible' : ''}`}
                            src={require('../../img/header_logo.svg').default}
                            alt='logo'
                        />
                    </NavLink>
                    <input 
                        id='burger'
                        className='header__burger'
                        type='checkbox'
                        onChange={handleCheckboxChange}
                        checked={isSlideMenuOpen}
                    >
                    </input>
                    <label 
                        htmlFor='burger'
                        className={`header__burger_label header__animated ${isSmoothAppearance ? 'header--visible' : ''}`}>
                    </label>
                    <nav className='header__nav-menu'>
                        <ul className={`header__menu_list ${isSlideMenuOpen ? 'header__slider-menu_active' : ''} ${isSlideMenuCLose ? 'header__slider-menu_inactive' : ''}`}>
                            <li 
                                className={`header__menu_item header__animated ${isSmoothAppearance ? 'header--visible' : ''}`} 
                                style={{ transitionDelay: `0.1s` }}
                            >
                                <NavLink
                                    onClick={()=>{closeMenu(true)}}
                                    className='header__menu_link animated_underline'
                                    to="/offices"
                                >
                                    {t('header.link_1')}
                                </NavLink>
                            </li>
                            <li 
                                className={`header__menu_item header__animated  ${isSmoothAppearance ? 'header--visible' : ''}`} 
                                style={{ transitionDelay: `0.2s` }}
                            >
                                <NavLink
                                    onClick={()=>{closeMenu(true)}}
                                    className='header__menu_link animated_underline'
                                    to="/services"
                                >
                                    {t('header.link_2')}
                                </NavLink>
                            </li>
                            <li 
                                className={`header__menu_item header__animated  ${isSmoothAppearance ? 'header--visible' : ''}`} 
                                style={{ transitionDelay: `0.3s` }}
                            >
                                <NavLink
                                    onClick={()=>{closeMenu(true)}}
                                    className='header__menu_link animated_underline'
                                    to="/about"
                                >
                                    {t('header.link_3')}
                                </NavLink>
                            </li>
                            <li 
                                className={`header__menu_item header__animated ${isSmoothAppearance ? 'header--visible' : ''}`} 
                                style={{ transitionDelay: `0.4s` }}
                             >
                                <NavLink
                                    onClick={()=>{closeMenu(true)}}
                                    className='header__menu_link animated_underline'
                                    to="/contact"
                                >
                                    {t('header.link_4')}
                                </NavLink>
                            </li>
     
                        </ul>
                    </nav>
                    <div className={`header__links ${isSlideMenuOpen ? 'header__slider-menu_active' : ''}`}>
                        <div className={`header__auth_container header__animated  ${isSmoothAppearance ? 'header--visible' : ''}`}>
                            <NavLink 
                                className='header__link-auth animated_underline' 
                                onClick={()=>{closeMenu(true)}}
                                to="/login"
                            >
                                {t('header.login')}
                            </NavLink>
                        </div>
                        <ul className='header__lang_list'>
                            <li className={`header__lang_item header__animated ${isSmoothAppearance ? 'header--visible' : ''}`}>
                                <a 
                                    className={`header__lang_link ${isLandguageEnglish ? 'header__selected' : ''}`}
                                    href="/"
                                    onClick={(e) => handleLang('en', e)}
                                    >{t('header.lang_1')}
                                </a>
                            </li>
                            <li className={`header__lang_item header__animated ${isSmoothAppearance ? 'header--visible' : ''}`}>
                                <a 
                                    className= {`header__lang_link ${!isLandguageEnglish ? 'header__selected' : ''}`}
                                    href="/"      
                                    onClick={(e) => handleLang('fr', e)}
                                    >{t('header.lang_2')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className='header__ident'></div>
        </>
    );
}

export default Header;
