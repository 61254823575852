import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

const ScrollToTopOnRouteChange = () => {
  const { scroll } = useLocomotiveScroll();
  const location = useLocation();

  useEffect(() => {
    if (scroll) {
      scroll.scrollTo(0, { duration: 0, disableLerp: true });
    }
  }, [location, scroll]);

  return null;
};

export default ScrollToTopOnRouteChange;