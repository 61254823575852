import React, { useEffect, useState } from 'react';
import Icons from '../../Icons/Icons';
import ParallaxComponent from '../../Parallax/ParallaxComponent'

import { iconListAmenities, ImageParallaxAmenities } from '../../../constants/data-main-page';

function Amenities( props ) {
    const { windowWidth, windowHeight, t } = props;
    const [backPositionY, setBackPositionY] = useState(150);
    const [backPositionX, setBackPositionX] = useState(0);
    const [heightParallax, setHeightParallax] = useState(1280);
    const [speedParallax, setSpeedParallax] = useState(-8);

    useEffect(() => {
        if (windowWidth > 1575) {
            setBackPositionY(-150);
            setSpeedParallax(-5);
            setBackPositionX(0);
            const newHeight = (windowWidth * 2) / 4;
            setHeightParallax(`${newHeight}px`);
        } if (windowWidth <=1575 && windowWidth > 1280) {
            setBackPositionY(-150);
            setSpeedParallax(-5);
            setBackPositionX(0);
            const newHeight = (windowWidth * 2) / 3;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1280 && windowWidth > 1020){
            setBackPositionY(-350);
            setBackPositionX((-26 * windowWidth) / 100);
            setSpeedParallax(-2);
            const newHeight = (windowWidth * 2) / 2;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1020 && windowWidth > 500){
            setBackPositionY(-200);
            setBackPositionX(0);
            setSpeedParallax(-1.5);
            const newHeight = (windowWidth * 2) / 2.4;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 500){
            setBackPositionY(0);
            setBackPositionX((-12 * windowWidth) / 100);
            setSpeedParallax(-1);
            const newHeight = (windowWidth * 2) /2.2;
            setHeightParallax(`${newHeight}px`);
        }
}, [windowWidth, windowHeight]);

    return (
        <section className='amenities'>
            <div className='amenities__title_container'>
                <h2 className='amenities__title'>{t('main.amenities.title')}</h2>
            </div>
            <Icons iconList={iconListAmenities} sectionName={'amenities'} t={t}/>
            <ParallaxComponent 
                image={ ImageParallaxAmenities } 
                backPositionX = {backPositionX}
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {100}
                speedParallax = {speedParallax}
            ></ParallaxComponent>
        </section>
    );
}

export default Amenities;