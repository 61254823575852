import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function ThankYou( props ) {
    const { t } = props;
    const location = useLocation();
    const from = location.state?.from;

    const pathLink = () => {
        if (from === 'request') {
            return '/'
        } else return '/contact';
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='thank' data-scroll-section>
            <div className='thank__container'>
                <h2 className='thank__title'>{t('thankyou.title')}</h2>
                {from && <p>{t('thankyou.subtitle.start_text')}{from}{t('thankyou.subtitle.finish_text')}</p>}
                <HashLink className='thank__link' smooth to={pathLink()}>
                    <span className='thank__arrow_link'></span>
                    <p>{t('thankyou.back')}</p>
                </HashLink>
            </div>    
        </div>
    );
}

export default ThankYou;
