import React, { useState, useEffect} from 'react';
import Icon from './Icon/Icon';

function Icons( props ) {
    const { iconList, sectionName, t } = props;
    const [classSection, setClassSection] = useState('');

    useEffect(() => {
        if (sectionName === 'advants') {
            setClassSection('icons')
        } else if (sectionName === 'additional') {
            setClassSection('icons__additional')
        } else if (sectionName === 'amenities') {
            setClassSection('icons__amenities')
        }
    }, [sectionName]);

    return (
        <div className={classSection}>
            {iconList.map((icon, index) => (
                <Icon 
                    key={index} 
                    Picture={icon.Picture} 
                    title={icon.title_1} 
                    title_2={icon.title_2} 
                    sectionName={sectionName} 
                    iconKey={index}
                    t={t}
                />
            ))}
            {sectionName === 'amenities' && <p className='amenities__subtitle'>
                {t('main.amenities.booking.text')}<br/>
                <a 
                    className='amenities__subtitle_link' 
                    href={t('main.amenities.booking.link')}
                    target="_blank"
                    rel="noopener noreferrer"
                >{t('main.amenities.booking.text_link')}
                </a>
            </p>
            }
        </div>
    );
}

export default Icons;
