import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Icons from '../../Icons/Icons';
import ParallaxComponent from '../../Parallax/ParallaxComponent';
import { iconListAdditional, ImageParallaxAdditional }from '../../../constants/data-main-page';

function Additional( props ) {
    const { windowWidth, windowHeight, t } = props;
    const [backPositionY, setBackPositionY] = useState(150);
    const [backPositionX, setBackPositionX] = useState(0);
    const [heightParallax, setHeightParallax] = useState(1280);
    const [widthParallax, setWidthParallax] = useState(100);
    const [speedParallax, setSpeedParallax] = useState(-8);

    useEffect(() => {
        if (windowWidth > 1575) {
            setBackPositionY(-100);
            setSpeedParallax(-9);
            setBackPositionX(0);
            setWidthParallax(100);
            const newHeight = (windowWidth * 2) / 3;
            setHeightParallax(`${newHeight}px`);
        } if (windowWidth <=1575 && windowWidth > 1280) {
            setBackPositionY(-150);
            setSpeedParallax(-5);
            setBackPositionX(0);
            setWidthParallax(100);
            const newHeight = (windowWidth * 2) / 3;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1280 && windowWidth > 1020){
            setBackPositionY(-350);
            setBackPositionX((-26 * windowWidth) / 100);
            setSpeedParallax(-1.5);
            setWidthParallax(160);
            const newHeight = (windowWidth * 2) / 2.5;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 1020 && windowWidth > 500){
            setBackPositionY(-200);
            setBackPositionX((-45 * windowWidth) / 100);
            setWidthParallax(100);
            setSpeedParallax(-2);
            const newHeight = (windowWidth * 2) / 1.8;
            setHeightParallax(`${newHeight}px`);
        } else if (windowWidth <= 500){
            setBackPositionY(-50);
            setBackPositionX((-45 * windowWidth) / 100);
            setWidthParallax(100);
            setSpeedParallax(-2);
            const newHeight = (windowWidth * 2) / 1.8;
            setHeightParallax(`${newHeight}px`);
        }
}, [windowWidth, windowHeight]);

    return (
        <section className='additional'>
            <div className='additional__container'>
                <div className='additional__title_container'>
                    <h2 className='additional__title'>{t('main.additional.title')}</h2>
                    <NavLink 
                        className='additional__link' 
                        to='/services'
                    >
                        <p>{t('main.additional.link')}</p>
                        <span className='arrow__link'></span>
                    </NavLink>
                </div>
                <Icons iconList={ iconListAdditional } sectionName={'additional'}/> 
            </div>
            <ParallaxComponent 
                image={ ImageParallaxAdditional } 
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {widthParallax}
                speedParallax = {speedParallax}
                backPositionX ={backPositionX}
            ></ParallaxComponent>
        </section>
    );
}

export default Additional;
