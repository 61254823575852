//picture for Intro
import Slide1 from '../img/intro_slides/slides_1.png';
import Slide2 from '../img/intro_slides/slides_2.png';
import Slide3 from '../img/intro_slides/slides_3.png';

//pictures for carousel
import img1 from '../img/carousel_img/img_1.png';
import img2 from '../img/carousel_img/img_2.png';
import img3 from '../img/carousel_img/img_3.png';
import img4 from '../img/carousel_img/img_4.png';
import img5 from '../img/carousel_img/img_5.png';
import img6 from '../img/carousel_img/img_6.png';
import img7 from '../img/carousel_img/img_7.png';
import img8 from '../img/carousel_img/img_8.png';

//svg icons and pic parallax for Advants block
import { ReactComponent as Email } from '../img/icons/twoLayer/advants-block/mail-address.svg';
import { ReactComponent as Furniture } from '../img/icons/twoLayer/advants-block/furniture.svg';
import { ReactComponent as Supplies } from '../img/icons/twoLayer/advants-block/office-supplies.svg';
import { ReactComponent as Service } from '../img/icons/twoLayer/advants-block/concierge.svg';
import { ReactComponent as Ventilation } from '../img/icons/twoLayer/advants-block/ventilation.svg';
import { ReactComponent as Cleaning } from '../img/icons/twoLayer/advants-block/cleaning.svg';
import { ReactComponent as Wifi } from '../img/icons/twoLayer/advants-block/wifi.svg';
import { ReactComponent as Booth } from '../img/icons/twoLayer/advants-block/phone-booth.svg';
import { ReactComponent as Parking } from '../img/icons/twoLayer/advants-block/bike-parking.svg';
import { ReactComponent as Tea } from '../img/icons/twoLayer/advants-block/tea-corner.svg';
import { ReactComponent as Security } from '../img/icons/twoLayer/advants-block/privacy-security.svg';
import { ReactComponent as Terrace } from '../img/icons/twoLayer/advants-block/outdoor-terrace.svg';
import ImageParallaxAdvants from '../img/parallax/img_par_1.jpg';

//svg icons and pic parallax for Additional block
import { ReactComponent as Car } from '../img/icons/twoLayer/additional-block/car-parking.svg';
import { ReactComponent as Meeting } from '../img/icons/twoLayer/additional-block/meeting-room.svg';
import { ReactComponent as Gym } from '../img/icons/twoLayer/additional-block/gym.svg';
import ImageParallaxAdditional from '../img/parallax/img_par_2.jpg';

//svg icons and pic parallax for Amenities block
import { ReactComponent as Lunch } from '../img/icons/twoLayer/amenities-block/cafe.svg';
import { ReactComponent as Suite } from '../img/icons/twoLayer/amenities-block/coliving.svg';
import  ImageParallaxAmenities  from '../img/parallax/img_par_3.jpg';

const slideListIntro = [
    {picture: Slide1},
    {picture: Slide2},
    {picture: Slide3},
]

const imageCarouselList = [
    { picture: img1 },
    { picture: img2 },
    { picture: img3 },
    { picture: img4 },
    { picture: img5 },
    { picture: img6 },
    { picture: img7 },
    { picture: img8 },
];

const iconListAdvants = [
    { Picture: Email, title_1: 'Mail address', title_2: 'Adresse email' },
    { Picture: Furniture, title_1: 'Ergonomic furniture', title_2: 'Mobilier ergonomique' },
    { Picture: Supplies, title_1: 'Office supplies', title_2: 'Fournitures de bureau' },
    { Picture: Service, title_1: 'Concierge service', title_2: 'Service de conciergerie' },
    { Picture: Ventilation, title_1: 'Advanced ventilation', title_2: 'Ventilation avancée' },
    { Picture: Cleaning, title_1: 'Cleaning services', title_2: 'Services de nettoyage' },
    { Picture: Wifi, title_1: 'High speed Wi-Fi', title_2: 'Wi-Fi haut débit' },
    { Picture: Booth, title_1: 'Phone booth', title_2: 'Cabine téléphonique' },
    { Picture: Parking, title_1: 'Bike parking', title_2: 'Parking à vélos' },
    { Picture: Tea, title_1: 'Tea corner', title_2: 'Coin thé' },
    { Picture: Security, title_1: 'Privacy & Security', title_2: 'Confidentialité et sécurité' },
    { Picture: Terrace, title_1: 'Outdoor terrace', title_2: 'Terrasse extérieure' }
]

const iconListAdditional = [
    { Picture: Meeting, title_1: 'Large meeting room', title_2: 'Grande salle de réunion' },
    { Picture: Gym, title_1: 'Gym with modern equipment', title_2: 'Salle de sport avec équipements modernes' },
    { Picture: Car, title_1: 'Car parking lots', title_2: 'Parkings pour voitures' },
];

const iconListAmenities = [
    { Picture: Lunch, title_1: 'Take a break in our Café for breakfast, lunch or dinner', title_2: 'Faites une pause dans notre café pour le petit-déjeuner, le déjeuner ou le dîner' },
    { Picture: Suite, title_1: 'Get a gorgeous suite room at HENRY’s coliving', title_2: 'Profitez d\'une magnifique suite chez HENRY’s coliving' },
];


export {
    slideListIntro,
    imageCarouselList,
    iconListAdvants,
    iconListAdditional,
    iconListAmenities,
    ImageParallaxAdvants,
    ImageParallaxAdditional,
    ImageParallaxAmenities,
};