import React, { useState, useEffect, useRef, useContext } from 'react';
import { CurrentLangContext } from '../../contexts/CurrentLangContext';

function Icon(props) {
    const { iconKey, Picture, title, title_2, sectionName } = props;
    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();
    const pictureRef = useRef(null);
    const [classSection, setClassSection] = useState('');
    const [isFirstBlock, setIsFirstBlock] = useState(false);
    const currentLang = useContext(CurrentLangContext);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const svgRect = pictureRef.current.getBoundingClientRect();
            setMouseX(event.clientX - svgRect.left);
            setMouseY(event.clientY - svgRect.top);
        };
    
        const svgElement = pictureRef.current;
        svgElement.addEventListener("mousemove", handleMouseMove);
    
        const handleMouseLeave = () => {
            const rects = pictureRef.current.querySelectorAll('g');
            rects.forEach(rect => {
                rect.style.transform = 'none';
            });
        };
    
        svgElement.addEventListener("mouseleave", handleMouseLeave);
    
        return () => {
            svgElement.removeEventListener("mousemove", handleMouseMove);
            svgElement.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    useEffect(() => {
        if (pictureRef.current) {
            const svgRect = pictureRef.current.getBoundingClientRect();
            const centerX = svgRect.width / 2;
            const centerY = svgRect.height / 2;
            const rects = pictureRef.current.querySelectorAll('g');
    
            rects.forEach((rect, index) => {
                if (index === 0) {
                    rect.style.transform = `translate(${(mouseX - centerX) * 0.05}px, ${(mouseY - centerY) * 0.05}px)`;
                } else {
                    rect.style.transform = `translate(${(mouseX - centerX) * -0.05}px, ${(mouseY - centerY) * -0.05}px)`;
                }
            });
        }
    }, [mouseX, mouseY]);

    useEffect(() => {
        if (sectionName === 'advants') {
            setClassSection('icon')
        } else if (sectionName === 'additional') {
            setClassSection('icon__additional')
        } else if (sectionName === 'amenities') {
            setClassSection('icon__amenities')
            if (iconKey === 0) {
                setIsFirstBlock(true)
            } else {
                setIsFirstBlock(false)
            }
        }
    }, [sectionName, iconKey]);

    return (
        <div className={classSection}>
            <div className='icon__container' ref={pictureRef}>
                <Picture className={sectionName === 'amenities' ? `icon__picture_amenities ${isFirstBlock && 'icon__picture_amenities-first'}` : 'icon__picture'} />
            </div>
            <p className={`${classSection}__title ${isFirstBlock && 'icon__amenities_title-first'}`}>{currentLang === 'en' ? title : title_2}</p>
        </div>
    );
}

export default Icon;
