import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Carousel from '../../Carousel/Carousel';
import Icons from '../../Icons/Icons';
import ParallaxComponent from '../../Parallax/ParallaxComponent'
import { iconListAdvants, ImageParallaxAdvants } from '../../../constants/data-main-page';

function Advants( props ) {
    const { windowWidth, windowHeight, t } = props;
    const [backPositionY, setBackPositionY] = useState(150);
    const [heightParallax, setHeightParallax] = useState(0);
    const [widthParallax, setWidthParallax] = useState(0);
    const [speedParallax, setSpeedParallax] = useState(0);

    useEffect(() => {
            if (windowWidth > 1280) {
                setBackPositionY(70);
                setWidthParallax(100);
                setSpeedParallax(-6);
                const newHeight = (windowWidth * 2) / 3;
                setHeightParallax(`${newHeight}px`);
            } else if (windowWidth <= 1280 && windowWidth > 1020){
                setBackPositionY(-200);
                setSpeedParallax(-2);
                setWidthParallax(115);
                const newHeight = (windowWidth * 2) / 2.2;
                setHeightParallax(`${newHeight}px`);
            } else if (windowWidth <= 1020 && windowWidth > 500){
                setBackPositionY(-200);
                setSpeedParallax(-2);
                setWidthParallax(150);
                const newHeight = (windowWidth * 2) / 1.4;
                setHeightParallax(`${newHeight}px`);
            } else if (windowWidth <= 500){
                setBackPositionY(-20);
                setWidthParallax(150);
                setSpeedParallax(-0.5);
                setHeightParallax('120%');
            }
    }, [windowWidth, windowHeight]);

    return (
        <section className='advants'>
            <div className='advants__title_container'>
                <h2 className='advants__title'>{t('main.advants.title')}</h2>
                <NavLink className='advants__link' to="/offices">
                    <p>{t('main.advants.link')}</p>
                    <span className='arrow__link'></span>
                </NavLink>
            </div>
            <Carousel windowWidth ={windowWidth}></Carousel>
            <Icons iconList={iconListAdvants} sectionName={'advants'} t={t}/>
            <ParallaxComponent 
                image={ ImageParallaxAdvants }
                backPositionY = {backPositionY}
                heightParallax = {heightParallax}
                widthParallax = {widthParallax}
                speedParallax = {speedParallax}
            >
            </ParallaxComponent>
        </section>
    );
}

export default Advants;
