import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer ( props ) {
    const { t } = props;

    return (
        <section className='footer' data-scroll-section>
            <div className='footer__container'>
                <p className='footer__copyright'>{t('footer.copyright')}</p>
                <div className='footer__info'>
                    <div className='footer__block_info'>
                        <p className='footer__block_title'>{t('footer.work.title')}</p>
                        <ul className='footer__block_list'>
                            <li className='footer__block_item'><NavLink to={t('footer.work.link_1.link')}>{t('footer.work.link_1.title')}</NavLink></li>
                            <li className='footer__block_item footer__block_item-about'><NavLink to={t('footer.work.link_2.link')}>{t('footer.work.link_2.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.work.link_3.link')}>{t('footer.work.link_3.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.work.link_4.link')}>{t('footer.work.link_4.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.work.link_5.link')}>{t('footer.work.link_5.title')}</NavLink></li>
                        </ul>
                    </div>
                    <div className='footer__block_info'>
                        <p className='footer__block_title'>{t('footer.info.title')}</p>
                        <ul className='footer__block_list'>
                            <li className='footer__block_item footer__item_hidden'><NavLink to={t('footer.info.link_1.link')}>{t('footer.info.link_1.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.info.link_2.link')}>{t('footer.info.link_2.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.info.link_3.link')}>{t('footer.info.link_3.title')}</NavLink></li>
                        </ul>
                    </div>
                    <div className='footer__block_info footer__block_account'>
                        <p className='footer__block_title'>{t('footer.account.title')}</p>
                        <ul className='footer__block_list'>
                            <li className='footer__block_item'><NavLink to={t('footer.account.link_1.link')}>{t('footer.account.link_1.title')}</NavLink></li>
                            <li className='footer__block_item'><NavLink to={t('footer.account.link_2.link')}>{t('footer.account.link_2.title')}</NavLink></li>
                        </ul>
                    </div>
                    <div className='footer__block_info footer__block-icon'>
                        <p className='footer__block_title footer__block-icons_title'>{t('footer.getintouch.title')}</p>
                        <ul className='footer__block_list footer__block-icon_list'>
                            <li className='footer__block_item'>
                                <a href="https://www.instagram.com"><img
                                    className='footer__block-icon_item'
                                    src={require('../../img/instagram_icon.svg').default}
                                    alt='instagram logo'
                                /></a>
                            </li>
                            <li className='footer__block_item'>
                                <a href="https://www.pinterest.com"><img
                                    className='footer__block-icon_item'
                                    src={require('../../img/pinterest_icon.svg').default}
                                    alt='pinterest logo'
                                /></a>
                            </li>
                            <li className='footer__block_item'>
                                <a href="https://telegram.org"><img
                                    className='footer__block-icon_item'
                                    src={require('../../img/telegram_icon.svg').default}
                                    alt='telegram logo'
                                /></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='footer__logo'>
                    <img
                        className='footer__logo_img'
                        src={require('../../img/large_logo.png')}
                        alt='large logo'
                    />
                </div>
            </div>
        </section>
    );
}

export default Footer;