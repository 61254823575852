import React, { useEffect, useContext }from 'react';
import { useLocation } from 'react-router-dom';
import Intro from './Intro/Intro.js';
import Advants from './Advants/Advants.js';
import Additional from './Additional/Additional.js';
import Amenities from './Amenities/Amenities.js';
import Request from './Request/Request.js';
import Faq from './Faq/Faq.js';
import { LocomotiveScrollContext } from 'react-locomotive-scroll';

function Main( props ) {
    const { windowWidth, windowHeight, t } = props;
    const { scroll } = useContext(LocomotiveScrollContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    const nameOffice = location.state?.nameOffice;

    useEffect(() => {
        const sessionKey = `scrolled_${location.key}`;
        const scrolled = sessionStorage.getItem(sessionKey);

        if ((source === 'request' || nameOffice) && !scrolled) {
            const scrollToTarget = () => {
                scroll.scrollTo('#main_request', {
                    offset: -40,
                });
                sessionStorage.setItem(sessionKey, 'true');
            };

            const timer = setTimeout(scrollToTarget, 200);
            return () => clearTimeout(timer);
        }

        if ((source === 'faq') && !scrolled) {
            const scrollToTarget = () => {
                scroll.scrollTo('#faq', {
                    offset: -10,
                });
                sessionStorage.setItem(sessionKey, 'true');
            };

            const timer = setTimeout(scrollToTarget, 200);
            return () => clearTimeout(timer);
        }
    }, [scroll, nameOffice, source, location.key]);

    return (
        <main className="main" data-scroll-section>
            <Intro t={t}></Intro>
            <Advants windowWidth = {windowWidth} windowHeight={windowHeight} t={t}></Advants>
            <Additional windowWidth = {windowWidth} windowHeight={windowHeight} t={t}></Additional>
            <Amenities windowWidth = {windowWidth} windowHeight={windowHeight} t={t}></Amenities>
            <Request nameOffice={nameOffice} t={t}></Request>
            <Faq t={t}></Faq>
        </main>
    );
}

export default Main;
